import React, { useEffect, useState } from "react";
import { Mainlayout } from "../../layouts";
import { HelmetHead, Plans } from "../../components";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useSelector } from "react-redux";
import { fetchData } from "../../axios/CommonApisFunctions.js";

const Pricing = () => {
  const [open, setOpen] = useState(0);
  const [loading, setloading] = useState(false);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const [Faqs, setFaqs] = useState([]);
  const { Language } = useSelector((state) => state.user);

  useEffect(() => {
    const Fetch = async () => {
      setloading(true);
      try {
        const responseData = await fetchData(Language);
        setloading(false);
        setFaqs(responseData);
      } catch (error) {
        setloading(false);
        console.error("Error occurred:", error.message);
      }
    };

    Fetch();
  }, [Language]);

  return (
    <Mainlayout>
      <HelmetHead title="Pricing" description="Ai Pricing" />
      <div className="sm:py-20 py-10 min-h-screen">
        <div className="flex flex-col items-center space-y-3">
          <h1 className="text-[#008E3A] text-[40px] font-medium leading-10 font-primary text-center">
            Тарифы
          </h1>
          <h1 className="text-[#222] font-tertiary text-2xl font-medium leading-7 text-center">
            Выберите план, который лучше всего подходит для вас
          </h1>
          <p className="text-[#555] font-tertiary text-[15px] leading-7 font-medium text-center">
            Наш простой и удобный интерфейс упрощает создание контента. И наш
            три тарифных плана делают Aiword доступным для всех.
          </p>
        </div>

        {/* /// Pricing plans */}
        <div className="py-8 w-full">
          <Plans />
        </div>

        {/* /// Faq Secrion */}

        <div className="sm:my-20 my-10 font-tertiary">
          <div className="space-y-2">
            <h1 className="text-[#222] text-2xl font-medium leading-7 text-center">
              Часто задаваемые вопросы
            </h1>
            <p className=" text-[#555] text-[15px] font-normal leading-7 text-center">
              Здесь вы найдете часто задаваемые вопросы о контенте Aiword AI.
              тарифные планы генераторов
            </p>
          </div>
          <div className="my-10 space-y-6">
            {loading && "Loading...."}
            {Faqs &&
              Faqs.map((Acc) => {
                return (
                  <Accordion
                    key={Acc.id}
                    className="overflow-hidden border border-[#E9E9E9] bg-white p-6 hover:shadow-md duration-150"
                    open={open === Acc.id}
                  >
                    <AccordionHeader
                      className={`text-[#222] flex justify-start items-start space-x-3 sm:text-[18px] text-sm font-medium leading-5 border-none`}
                      onClick={() => handleOpen(Acc?.id)}
                    >
                      <span className="text-[#BABABA]">
                        {open === Acc.id ? <FaMinus /> : <FaPlus />}
                      </span>{" "}
                      <p>{Acc.title}</p>
                    </AccordionHeader>
                    <AccordionBody className="px-8 py-5">
                      <p dangerouslySetInnerHTML={{ __html: Acc?.en }} />
                    </AccordionBody>
                  </Accordion>
                );
              })}
          </div>
        </div>
      </div>
    </Mainlayout>
  );
};

export default Pricing;
