import React from "react";

const Subscribe = () => {
  return (
    <div className="SubScribemain w-full lg:gap-0 gap-7 grid grid-cols-7 font-primary mt-20">
      <div className="ContentBox col-span-7 md:col-span-4">
        <h1 className="sm:text-[32px] text-lg font-semibold sm:leading-[60px] text-black ">
          Присоединяйтесь к нам чтобы не пропустить важные новости
        </h1>
      </div>
      <div className="SubscribeBox space-y-3 col-span-7 md:col-span-3">
        <p className="font-medium sm:text-lg text-sm leading-7 text-[#4B4B4B]">
          Присоединяйтесь к списку Aiword, чтобы быть в курсе последних новостей
          и предложений.
        </p>
        <div className="SubuscribeBtn flex justify-between gap-2">
          <div className="flex gap-2 w-[80%] p-[0px_16px]">
            <input
              className="font-semibold outline-none text-[#737373] sm:text-sm text-[10px] w-full"
              type="email"
              placeholder="Введите адрес электронной почты."
            />
          </div>
          <button className="SubcribeBtn text-[14px] font-semibold leading-6 px-4 md:px-8">
            Отправить
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
