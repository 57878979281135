import React from "react";
import { Mainlayout } from "../../layouts";
import { CompanyDetails, HelmetHead } from "../../components";

const Requisites = () => {
  return (
    <Mainlayout>
      <HelmetHead title="Requisites" description="Ai Requisites" />
      <div className="my-20 min-h-screen">
        <CompanyDetails />
      </div>
    </Mainlayout>
  );
};

export default Requisites;
