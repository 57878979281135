import React from "react";
import { ArrowDown, Logo } from "../../assets";
import { Link } from "react-router-dom";
import { FooterList } from "./footerlist";
import { SocialLinks } from "../navbar/socialLinks";

const Footer = () => {
  const handleScrollToWorkflow = () => {
    const workflowSection = document.getElementById("workflow-section");
    const yOffset = -150;

    if (workflowSection) {
      const y =
        workflowSection.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  return (
    <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-10 mb-10 pt-4">
      <div className="col-span-1 lg:col-span-3 space-y-5">
        <img src={Logo} alt="Logo.svg" />
        <p className="text-[#4B4B4B] text-[14px] font-normal leading-5 font-primary md:text-left">
          Aiword - это онлайн-платформа, которая использует передовые технологии
          искусственного интеллекта для создания контента высокого качества. У
          нас есть более 60 инструментов для написания контента, которые помогут
          вам создавать уникальный материал.
        </p>
        <div className="flex">
          {SocialLinks &&
            SocialLinks.map((socail, index) => {
              return (
                <div
                  key={index}
                  className={`w-[49px] h-[47px] ${
                    index === 0 ? "order-1" : ""
                  } `}
                >
                  <Link to={socail.link}>
                    <img src={socail.Logo} alt="socail.svg" />
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
      {FooterList &&
        FooterList.map((item, index) => (
          <div
            key={index}
            className="col-span-1 lg:col-span-2 font-primary flex flex-col space-y-5"
          >
            <h1 className="font-semibold leading-6 text-[18px] md:text-left">
              {item.heading}
            </h1>
            <ul className="text-[#424242] font-normal text-[15px] flex flex-col space-y-4">
              {item.lists.map((li, ind) => (
                <Link
                  onClick={handleScrollToWorkflow}
                  className="leading-5 hover:text-[#008E3A] hover:underline transition-all duration-150 w-max"
                  key={ind}
                  to={li.link}
                >
                  <li>{li.list}</li>
                </Link>
              ))}
            </ul>
          </div>
        ))}
      <div className="col-span-1 lg:col-span-3 font-primary flex flex-col space-y-5">
        <h1 className="font-semibold leading-5 text-[18px] capitalize text-[#1E1E1E] md:text-left">
          Контактная информация
        </h1>
        <div className="flex flex-col space-y-4">
          <p className="text-[15px] text-[#9A9EA6] font-normal md:text-left">
            Присоединяйтесь к списку Aiword, чтобы быть в курсе последних
            новостей и предложений.
          </p>
          <div className="FooterSubcribe flex justify-between gap-2">
            <div className="flex gap-2">
              <img src={ArrowDown} alt="ArrowDown.svg" />
              <input
                className="font-semibold outline-none text-[#9A9EA6] text-[12px]"
                type="text"
                placeholder="Support@Aiword.ru"
              />
            </div>
            <button className="SubcribeBtn text-[14px] font-semibold leading-6 px-4 md:px-8">
              Отправить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
