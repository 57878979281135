import React from "react";
import { Mainlayout } from "../../layouts";
import { FAQ, HelmetHead } from "../../components";
import Herosection from "./Herosection";
import Choice from "./Choice";
import Features from "./features";
import Projects from "./projects";
import WorkFlow from "./Work";
import Subscribe from "./subscribe";

const Landing = () => {
  return (
    <Mainlayout>
      <HelmetHead title="Home" description="Ai Homepage" />
      <div className="min-h-screen">
        <div>
          <Herosection />
        </div>
        <div className="2xl:w-[70vw] w-[90%] m-auto">
          <div className="my-10">
            {/* /// Main Section ------- Section 1 */}
            <Projects />
          </div>
          <div
            id="workflow-section"
            className="my-10 transition-all duration-100"
          >
            {/* /// How its Work -------------------Section 4 */}
            <WorkFlow />
          </div>
          <div className="my-10">
            {/* /// Features Advanced AI ---------- Section 2 */}

            <Features />
          </div>
          <div className="my-10">
            {/* /// Who Can Choice it ------------- Section 3 */}
            <Choice />
          </div>
          <div>
            {/* /// Faq Section ------------------- Section 5 */}
            <FAQ />
          </div>
          <div className="my-10">
            {/* /// Faq Section ------------------- Section 5 */}
            <Subscribe />
          </div>
        </div>
      </div>
    </Mainlayout>
  );
};

export default Landing;
