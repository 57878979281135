const Navlists = [
  {
    list: "Как это работает",
    link: "/",
  },
  {
    list: "О нас",
    link: "/about",
  },
  {
    list: "Тарифы",
    link: "/pricing",
  },
  {
    list: "Связаться с нами",
    link: "/contact",
  },
  // {
  //   list: "Кейсы",
  //   link: "/contract",
  // },
];

export { Navlists };
