import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import { GetData, imageurl } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";
import { Link } from "react-router-dom";

const Choice = () => {
  const [SectionHead, setSectionHead] = useState({
    heading: "",
    heading_2: "",
    description: "",
  });
  const [Subsection, setSubSection] = useState([]);
  const { Language } = useSelector((state) => state.user);

  useEffect(() => {
    const obj = {
      page: "home",
      section: 4,
      lang_id: Language,
    };

    const fetchData = async () => {
      try {
        const Response = await httpRequest.post(GetData, obj);
        if (Response.status === 200 || Response.status === 201) {
          setSectionHead(Response?.data?.content);
          setSubSection(Response?.data?.sub_content);
        }
      } catch (err) {
        console.log("🚀 ~ fetchData ~ err:", err.message);
      }
    };
    fetchData();
  }, [Language]);

  return (
    <div className="font-primary">
      <div className="space-y-1">
        <h1 className="text-[#3FC27E] text-center text-lg font-medium leading-7">
          Aiword
        </h1>
        <h1 className="text-[#202648] text-center sm:text-4xl text-2xl font-bold leading-10">
          {SectionHead?.heading || "Кто может использовать?"}
        </h1>
        <p className="text-[#4E5478] sm:text-lg text-base font-normal leading-6 text-center">
          {SectionHead?.description ||
            "Веб-приложение AIword ориентировано на индивидуальных пользователей и бизнесы, которым требуется контент для их веб-сайта, блога, социальных сетей, а также для digital-маркетинговых агентств и стартапов."}
        </p>
        <div className="py-[43px] grid lg:grid-cols-3 sm:grid-cols-2 gap-9">
          {Subsection &&
            Subsection.map((choice) => {
              return (
                <div
                  key={choice.id}
                  className={`${styles.ChoiceBox} hover:shadow-lg duration-150 cursor-pointer `}
                >
                  <div className="w-full flex flex-col justify-between space-y-10 xl:min-h-96 lg:min-h-[500px] min-h-96">
                    <div className="flex flex-col space-y-7">
                      <div
                        className={`h-[60px] w-[60px] flex items-center justify-center border border-[#B4F4AA] rounded-full ${styles.ChoiceDrop}`}
                      >
                        <img
                          src={`${imageurl}/${choice.image}`}
                          alt="img.png"
                        />
                      </div>
                      <div className="space-y-2">
                        <h1 className="text-[#202648] text-[20px] font-bold leading-normal">
                          {choice?.heading || "N/A"}
                        </h1>
                        <p className="text-[#4E5478] text-lg font-normal leading-6">
                          {choice?.description || "N/A"}
                        </p>
                      </div>
                    </div>
                    <Link  className={styles.ChoiceBtn} to={"/pricing"}>
                      <button>
                        {" "}
                        {choice?.button_text || "Попробовать"}{" "}
                      </button>
                    </Link>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Choice;
