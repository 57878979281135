import { Linkdin, Telegram, yandex } from "../../assets";

const SocialLinks = [
  {
    Logo: yandex,
    link: "https://passport.yandex.com/auth?retpath=https%3A%2F%2Fyandex.com%2F&msid=1707711966588448-15539467997849065035-balancer-l7leveler-kubr-yp-vla-83-BAL-4488&origin=home_yaru_desktop_new",
  },
  {
    Logo: Linkdin,
    link: "https://vk.com/club224690320",
  },
  {
    Logo: Telegram,
    link: "https://t.me/+iRZFQF7mzbc2YzJk",
  },
];

export { SocialLinks };
