import React, { useEffect, useState } from "react";
import { Mainlayout } from "../../layouts";
import { HelmetHead } from "../../components";
import { useSelector } from "react-redux";
import { fetchStaticData } from "../../axios/CommonApisFunctions";

const Privacy = () => {
  const { Language } = useSelector((state) => state.user);
  const [Staticdata, setStaticData] = useState({});
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const Fetch = async () => {
      setloading(true);
      try {
        const Response = await fetchStaticData(Language, "privacy-policy");
        setStaticData(Response);
        setloading(false);
      } catch (err) {
        setloading(false);
        console.log("🚀 ~ fetchData ~ err:", err.message);
        throw err;
      }
    };
    Fetch();
  }, [Language]);

  return (
    <Mainlayout>
      <HelmetHead title="Policy" description="Ai Privacy-Policy" />
      <div className="min-h-screen font-secondry pb-20">
        <h1 className=" font-bold md:text-4xl text-xl text-center py-10">
          {Staticdata?.title}
        </h1>
        {loading && "Loading...."}
        <div className="space-y-5">
          <div
            dangerouslySetInnerHTML={{ __html: Staticdata?.descriptions }}
            className="text-[#000] md:text-[22px] text-sm font-normal leading-[45px] MainPolicy"
          />
        </div>
      </div>
    </Mainlayout>
  );
};

export default Privacy;
