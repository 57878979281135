import React, { useEffect, useState } from "react";
import { Mainlayout } from "../../layouts";
import { Shuttle } from "../../assets";
import { CompanyDetails, HelmetHead } from "../../components";
import { useSelector } from "react-redux";
import { GetData, imageurl } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";
import { Link } from "react-router-dom";
import { SignupLink } from "../../constants/apiEndPoints/Links.js";

const About = () => {
  const [SectionHead, setSectionHead] = useState({});
  const { Language } = useSelector((state) => state.user);

  useEffect(() => {
    const obj = {
      page: "about_us",
      section: 1,
      lang_id: Language,
    };

    const fetchData = async () => {
      try {
        const Response = await httpRequest.post(GetData, obj);
        if (Response.status === 200 || Response.status === 201) {
          setSectionHead(Response?.data?.content);
        }
      } catch (err) {
        console.log("🚀 ~ fetchData ~ err:", err.message);
      }
    };
    fetchData();
  }, [Language]);

  return (
    <Mainlayout>
      <HelmetHead title="About" description="Ai About" />
      <div className="min-h-screen sm:my-20 my-10">
        <div className=" w-full grid md:grid-cols-2 grid-cols-1 gap-3 font-primary">
          <div className=" space-y-12">
            <div className=" space-y-4">
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: SectionHead?.description,
                  }}
                  className="text-[#383838] md:text-2xl text-lg font-normal leading-8 MianAbout"
                />
              </p>
            </div>
            <Link className="block" to={SignupLink}>
              <button className="AboutBtn m-auto">
                <img src={Shuttle} alt="Shuttle.svg" />{" "}
                {SectionHead?.button_text || "Попробуйте бесплатно"}
              </button>
            </Link>
          </div>
          <div>
            <img
              className="h-auto w-full"
              src={`${imageurl}/${SectionHead?.media_url}`}
              alt="AboutMain.svg"
            />
            <p className="text-[#383838] text-[15px] font-normal leading-5 p-4 text-end">
              {SectionHead?.media_text ||
                "Фото создано при помощи Генератор изображений на сайте Aiword.ru"}
            </p>
          </div>
        </div>
        <div className="sm:py-32 py-10">
          <CompanyDetails />
        </div>
      </div>
    </Mainlayout>
  );
};

export default About;
