import { FAQS, StaticPages } from "../constants/apiEndPoints";
import httpRequest from "./index.js";

const fetchData = async (Language) => {
  let obj = {
    lang: Language === 2 ? "ru" : "en",
  };
  try {
    const Response = await httpRequest.post(FAQS, obj);
    if (Response.status === 200 || Response.status === 201) {
      return Response.data.data;
    }
  } catch (err) {
    console.log("🚀 ~ fetchData ~ err:", err.message);
    throw err;
  }
};

const fetchStaticData = async (Language, slug) => {
  let obj = {
    lang_id: Language,
    slug: slug,
  };
  try {
    const Response = await httpRequest.post(StaticPages, obj);
    if (Response.status === 200 || Response.status === 201) {
      return Response.data?.content;
    }
  } catch (err) {
    console.log("🚀 ~ fetchData ~ err:", err.message);
    throw err;
  }
};

export { fetchData, fetchStaticData };
