import React, { useEffect, useState } from "react";
import { GetData } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";
import { useSelector } from "react-redux";

const CompanyDetails = () => {
  const [SectionHead, setSectionHead] = useState({});
  const { Language } = useSelector((state) => state.user);

  useEffect(() => {
    const obj = {
      page: "about_us",
      section: 2,
      lang_id: Language,
    };

    const fetchData = async () => {
      try {
        const Response = await httpRequest.post(GetData, obj);
        if (Response.status === 200 || Response.status === 201) {
          setSectionHead(Response?.data?.content);
        }
      } catch (err) {
        console.log("🚀 ~ fetchData ~ err:", err.message);
      }
    };
    fetchData();
  }, [Language]);

  return (
    <div className="w-full bg-[#ecefed78] sm:p-8 p-4 rounded-2xl font-primary space-y-6">
      <h1 className="font-bold leading-9 sm:text-[25px] text-xl">
        {SectionHead?.heading || "Реквизиты Aiword.ru"}
      </h1>
      <div
        className="Dangeours"
        dangerouslySetInnerHTML={{ __html: SectionHead?.description }}
      />
    </div>
  );
};

export default CompanyDetails;
