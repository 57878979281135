import React, { useEffect, useState } from "react";
import { ListIcon, Shuttle } from "../../assets";
import { PLANS } from "../../constants/apiEndPoints";
import httpRequest from "../../axios";
import { useSelector } from "react-redux";
import { Plan1, Plan2, Plan3 } from "../../assets";
import { Link } from "react-router-dom";
import { SignupLink } from "../../constants/apiEndPoints/Links";

const Plans = () => {
  const [ActiveplanId, setActiveplanId] = useState(2);
  const { Language } = useSelector((state) => state.user);
  const [Allplans, setAllplans] = useState([]);
  // console.log("🚀 ~ Plans ~ Allplans:", Allplans);

  useEffect(() => {
    const fetchData = async () => {
      let obj = {
        lang_id: Language,
      };
      try {
        const Response = await httpRequest.post(PLANS, obj);
        if (Response.status === 200 || Response.status === 201) {
          if (Response?.data?.data) {
            const sortedPlans = Response?.data?.data.sort(
              (a, b) => b.id - a.id
            );
            setAllplans(sortedPlans);
          }
        }
      } catch (err) {
        console.log("🚀 ~ fetchData ~ err:", err.message);
        throw err;
      }
    };
    fetchData();
  }, [Language]);

  return (
    <div className="w-full grid lg:grid-cols-3 sm:grid-cols-2 gap-8">
      {Allplans &&
        Allplans.map((plan) => {
          return (
            <div
              key={plan.id}
              onMouseEnter={() => setActiveplanId(plan?.id)}
              className={`border flex flex-col ${
                plan?.id === "1" && "order-1"
              } space-y-6 items-center cursor-pointer ${
                plan?.id === ActiveplanId
                  ? "bg-[#DEF8ED]"
                  : "border-[#EFEFEF] bg-[#fff]"
              }  p-6  rounded-[10px] shadow-[0_4px_4px_rgba(0,0,0,0.25)] font-tertiary lg:h-max`}
            >
              <div className="h-[70px] w-[70px] rounded-full border border-[#3FC27E] flex items-center justify-center">
                <div
                  className={`h-[60px] w-[60px] rounded-full border ${
                    plan?.id === ActiveplanId ? "bg-[#3FC27E]" : "bg-[#EAF6FA]"
                  }  flex items-center justify-center`}
                >
                  <img
                    src={
                      plan.id === "1" ? Plan3 : plan.id === "2" ? Plan2 : Plan1
                    }
                    alt={plan.id}
                  />
                </div>
              </div>
              <h1 className="text-[#000] font-bold text-2xl leading-7">
                {plan.title}
                <hr
                  className={`w-full h-[2px] ${
                    plan.id === ActiveplanId ? "bg-[#3FC27E]" : "bg-[#D1D1D1]"
                  } `}
                />
              </h1>
              <div className="w-[90%] min-h-52 border-b pb-10">
                <ul className="space-y-2">
                  {plan.bullets &&
                    plan.bullets.map((service, index) => {
                      return (
                        <li
                          key={index}
                          className="flex space-x-2 text-[#444] font-medium leading-6"
                        >
                          {" "}
                          <img src={ListIcon} alt="ListIcon.svg" />{" "}
                          <span>{service}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="w-[90%] flex flex-col items-center space-y-5">
                <div className="text-center">
                  <h1 className="text-[#444] font-bold text-3xl leading-10">
                    ₽{plan.price}
                  </h1>
                  <p className="text-[#555] text-sm font-normal leading-5">
                    {plan.days === "30" && plan.price > 0
                      ? "В месяц"
                      : "Кредитная карта не требуется"}
                  </p>
                </div>
                <Link to={SignupLink}>
                  <button
                    className={`${
                      plan.title === "Бесплатно" ? "px-5" : "px-10"
                    } ${
                      plan.id === ActiveplanId ? "PlanBtn" : ""
                    }  border border-[#3FC27E] rounded-[30px] flex items-center justify-center gap-4 py-3`}
                  >
                    <img
                      className={`${
                        plan.title === "Бесплатно" ? "block" : "hidden"
                      } ${plan.id === ActiveplanId ? "invert" : ""} `}
                      src={Shuttle}
                      alt="Shuttle.svg"
                    />
                    <span className="uppercase">
                      {plan.price === "0"
                        ? "Попробуйте бесплатно"
                        : " Выберите план"}{" "}
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Plans;
