import React from "react";
import {
  About,
  Contact,
  Contract,
  Landing,
  Pricing,
  Privacy,
  Requisites,
} from "./pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ScrollToTop } from "./components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contract" element={<Contract />} />
        <Route path="/policy" element={<Privacy />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/requisites" element={<Requisites />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
