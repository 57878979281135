// Layout.js
import React, { useEffect, useState } from "react";
import { Navbar, Footer } from "../components";
import { useLocation } from "react-router-dom";
import BodyPictures from "../components/bodypictures/bodypictures";

const Mainlayout = ({ children }) => {
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex w-full flex-col min-h-screen">
      <BodyPictures />
      <div
        className={`w-full ${
          isScrolled ? "bg-white shadow-md" : "bg-[#eefef28b]"
        } ${location.pathname === "/" ? "fixed" : "sticky"} left-0 top-0 z-40`}
      >
        <header className="2xl:w-[70vw] w-[90%] m-auto h-[80px] flex justify-center items-center">
          <Navbar />
        </header>
      </div>
      <main
        className={`${
          location.pathname === "/" ? "100%" : "2xl:w-[70vw] w-[90%] m-auto"
        }  flex-1`}
      >
        {children}
      </main>

      <footer className="2xl:w-[70vw] w-[90%] m-auto flex justify-center items-center">
        <Footer />
      </footer>
      <p className="2xl:w-[70vw] w-[90%] text-center sm:text-start  m-auto border-t py-8 text-[#717171] text-[16px] font-primary leading-6 font-medium">
        2024 &copy; Aiword.ru Сайт aiword.ru, включая html-код, тексты, дизайн и
        прочие материалы, является объектом авторского права ИП Bоробей B.B.
      </p>
    </div>
  );
};

export default Mainlayout;
