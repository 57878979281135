import React, { useEffect, useState } from "react";
import { Mainlayout } from "../../layouts";
import { Linkdin, Telegram } from "../../assets";
import { Link } from "react-router-dom";
import { FAQ, HelmetHead } from "../../components";
import { ContactUs } from "../../constants/apiEndPoints";
import { useSelector } from "react-redux";
import httpRequest from "../../axios";
import { fetchStaticData } from "../../axios/CommonApisFunctions";
import { toast } from "react-toastify";

const Contact = () => {
  const { Language } = useSelector((state) => state.user);
  const [Staticdata, setStaticData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await httpRequest.post(ContactUs, formData);
      if (response) {
        toast.success("Form submitted successfully");
        handleCleanForm();
      }
    } catch (err) {
      console.error("Error submitting form:", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchStaticDataAPI = async () => {
      try {
        const response = await fetchStaticData(Language, "contact-us");
        setStaticData(response);
      } catch (err) {
        console.log("Error fetching static data:", err.message);
      }
    };
    fetchStaticDataAPI();
  }, [Language]);

  const handleCleanForm = () => {
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };

  return (
    <Mainlayout>
      <HelmetHead title="Contact" description="Ai Contact" />
      <div className="min-h-screen sm:py-20 py-10">
        <div className="font-primary">
          <h1 className="text-[#171717] text-center text-4xl font-normal leading-10">
            Контакты
          </h1>
          <div className="py-10 grid md:grid-cols-3 grid-cols-1 md:gap-16">
            <div className="md:col-span-2">
              <div className="space-y-3">
                <h1 className="text-[#171717] text-[24px] font-medium leading-7">
                  {Staticdata?.title || "Обращайтесь к нам"}
                </h1>
                <p
                  className="text-[#9A9A9A] text-[15px] font-normal leading-5"
                  dangerouslySetInnerHTML={{ __html: Staticdata?.descriptions }}
                />
              </div>
              <form name="ContactForm" className="my-8" onSubmit={handleSubmit}>
                <div className="grid sm:grid-cols-2 gap-6">
                  <input
                    type="text"
                    placeholder="Имя"
                    name="name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    className="border p-[11px_13px] rounded-[4px] text-[#757575] text-[15px] font-normal leading-normal border-[#DEDEDE] outline-none"
                  />
                  <input
                    type="email"
                    placeholder="Электронная почта"
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="border p-[11px_13px] rounded-[4px] text-[#757575] text-[15px] font-normal leading-normal border-[#DEDEDE] outline-none"
                  />
                  <textarea
                    required
                    name="message"
                    className="border min-h-48 sm:col-span-2 p-[11px_13px] resize-none rounded-[4px] text-[#757575] text-[15px] font-normal leading-normal border-[#DEDEDE] outline-none"
                    placeholder="Ваше сообщение"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  <div className="flex items-center sm:col-span-2 space-x-2 text-[15px] font-normal leading-5">
                    <input
                      required
                      className="custom-checkbox text-[#161D25] p-1 accent-neutral-50 border border-[#161D25]"
                      type="checkbox"
                    />
                    <span className="text-[#161D25]">
                      Отмечая это, вы соглашаетесь с нашими Условиями и
                      <Link to={"/policy"}>
                        <span className="text-[#0F379C] hover:underline">
                          Политикой конфиденциальности.
                        </span>
                      </Link>
                    </span>
                  </div>
                  <button
                    className="ContactBtn sm:col-span-2"
                    disabled={loading}
                  >
                    {loading ? "Отправка..." : "Отправить сейчас"}
                  </button>
                </div>
              </form>
            </div>
            <div className="h-max space-y-3">
              <h1 className="text-[#171717] text-lg font-medium leading-6">
                Информация
              </h1>
              <div className="space-y-2">
                <p className="text-[#9A9A9A] text-[15px] font-normal leading-5">
                  Просто воспользуйтесь одним из доступных вариантов связи с
                  нами.
                </p>
                <p className="text-[#9A9A9A] text-[15px] font-normal leading-5 cursor-pointer">
                  support@aiword.ru
                </p>
                <h1 className="text-[#171717] text-lg font-medium leading-6">
                  Социальные сети
                </h1>
                <div className="flex">
                  <div className="cursor-pointer">
                    <img src={Linkdin} alt="Linkdin.svg" />
                  </div>
                  <div className="cursor-pointer">
                    <img src={Telegram} alt="Linkdin.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:my-10 font-primary">
          <FAQ />
        </div>
      </div>
    </Mainlayout>
  );
};

export default Contact;
