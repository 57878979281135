import React, { useEffect, useState } from "react";
import { Mainlayout } from "../../layouts";
import { HelmetHead } from "../../components";
import { fetchStaticData } from "../../axios/CommonApisFunctions";
import { useSelector } from "react-redux";

const Contract = () => {
  const { Language } = useSelector((state) => state.user);
  const [Staticdata, setStaticData] = useState({});
  useEffect(() => {
    const Fetch = async () => {
      try {
        const Response = await fetchStaticData(Language, "contract");
        setStaticData(Response);
      } catch (err) {
        console.log("🚀 ~ fetchData ~ err:", err.message);
        throw err;
      }
    };
    Fetch();
  }, [Language]);

  return (
    <Mainlayout>
      <HelmetHead title="Contract" description="Ai Contract" />
      <div className="min-h-screen font-secondry sm:pb-20 pb-10">
        <h1 className=" font-bold md:text-4xl text-xl text-center sm:py-20 py-10">
          {Staticdata?.title ||
            "Оферта оказания услуг на предоставление доступа к платформе Aiword"}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: Staticdata?.descriptions }}
          className="space-y-5 ContractPage"
        />
      </div>
    </Mainlayout>
  );
};

export default Contract;
