import React, { useEffect, useState } from "react";
import { bodyimg1, bodyimg2, bodyimg3 } from "../../assets";
import { useLocation } from "react-router-dom";

const BodyPictures = () => {
  const location = useLocation();
  const [bodyHeightVH, setBodyHeightVH] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      const viewportHeight = window.innerHeight;
      const bodyHeightInPx = document.body.scrollHeight;
      const bodyHeightInVH = (bodyHeightInPx / viewportHeight) * 100;

      setBodyHeightVH(bodyHeightInVH);
    };

    updateHeight();

    window.addEventListener("resize", updateHeight);
    window.addEventListener("scroll", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
      window.removeEventListener("scroll", updateHeight);
    };
  }, []);

  const renderImage = (threshold, imgSrc, side, defaultTop, alternateTops) => {
    if (bodyHeightVH > threshold) {
      const topValue = alternateTops[location.pathname] || defaultTop;
      return (
        <img
          className={`absolute ${side} z-[-10] w-[100px] ${topValue}`}
          src={imgSrc}
          alt={`pic.png`}
        />
      );
    }
    return null;
  };

  return (
    <div className="lg:block hidden">
      {renderImage(100, bodyimg1, "left-0", "top-[70vh]", {
        "/": " top-[120vh]",
      })}
      {renderImage(170, bodyimg2, "right-0", "top-[170vh]", {
        "/": " top-[220vh]",
        "/contract": "top-[120vh]",
      })}
      {renderImage(350, bodyimg3, "left-0", "top-[270vh] ", {
        "/": "top-[320vh]",
      })}
      {renderImage(350, bodyimg3, "left-0", "top-[270vh]", {
        "/": "top-[470vh] ",
      })}
    </div>
  );
};

export default BodyPictures;
