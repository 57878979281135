const FooterList = [
  {
    heading: "Полезные ссылки",
    lists: [
      {
        list: "О нас",
        link: "/about",
      },
      {
        list: "Оферта",
        link: "/contract",
      },
      // {
      //   list: "Реквизиты",
      //   link: "/requisites",
      // },
      {
        list: "Политика конфиденциальности",
        link: "/policy",
      },
    ],
  },
  {
    heading: "Меню поддержки",
    lists: [
      {
        list: "Реквизиты",
        link: "/requisites",
      },
      {
        list: "Связаться с нами",
        link: "/contact",
      },
      {
        list: "Как это работает",
        link: "/",
      },
    ],
  },
];

export { FooterList };
