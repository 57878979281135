import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

const webDataSlice = createSlice({
  name: "WebData",
  initialState,
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },
});

export const { increment, decrement } = webDataSlice.actions;
export const selectWebData = (state) => state.WebData.value;
export default webDataSlice.reducer;
