import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  Landingback,
  Laptop,
  Shuttle,
  leftlower,
  leftupper,
  rightlower,
  rightupper,
} from "../../assets";
import { GetData } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SignupLink } from "../../constants/apiEndPoints/Links.js";

const Herosection = () => {
  const [Section, setSectionData] = useState({
    heading: "",
    heading_2: "",
    description: "",
  });
  const { Language } = useSelector((state) => state.user);
  const [videourl, setvideourl] = useState("");

  useEffect(() => {
    const obj = {
      page: "home",
      section: 1,
      lang_id: Language,
    };

    const fetchData = async () => {
      try {
        const Response = await httpRequest.post(GetData, obj);
        if (Response.status === 200 || Response.status === 201) {
          setSectionData(Response?.data?.content);
        }
        console.log(
          "🚀 ~ fetchData ~ Response?.data:",
          Response?.data?.base_url
        );
        let url = `${Response?.data?.base_url}${Response?.data?.content?.media_url}`;
        setvideourl(url);
      } catch (err) {
        console.log("🚀 ~ fetchData ~ err:", err.message);
      }
    };
    fetchData();
  }, [Language]);

  return (
    <div
      style={{
        backgroundImage: `url(${Landingback})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
      className={` pt-40 font-primary`}
    >
      <div className="ContentBox m-auto ">
        <div className=" m-auto  flex flex-col items-center 2xl:w-[48.64vw] lg:w-[70%] w-[90%] gap-[26px]">
          <h1 className="text-[#000] flex flex-col items-center space-y-1 text-center sm:text-[40px] text-3xl font-bold leading-10">
            <span>
              {Section?.heading || " Создавайте контент высокого качества"}
            </span>
            <span className={`${styles.MainHeading} px-6`}>
              {Section?.heading_2 || "помощью ИИ"}{" "}
            </span>
          </h1>
          <p className="text-center text-[#162135] sm:text-[17px] text-sm font-normal leading-7">
            {Section?.description ||
              "AIword - инновационный инструмент, использующий передовые технологии искусственного интеллекта, позволяющий создавать качественный, оптимизированный для поисковых запросов контент для вашего блога, веб-сайта и маркетинговых запросов. Более того, AIword поможет вам сэкономить время и ресурсы при выполнении работы"}
          </p>
          <Link to={SignupLink}>
            <button className={`${styles.MainBtn} sm:text-lg text-base`}>
              <img src={Shuttle} alt="Shuttle.svg" />
              {Section?.button_text || "Попробуйте бесплатно"}
            </button>
          </Link>
        </div>
      </div>
      <div className="w-[95%] m-auto relative sm:mt-44 my-16">
        <div className="w-full relative flex justify-between">
          <div className="w-[33%] absolute left-0">
            <img
              className="absolute left-0 top-0"
              src={leftlower}
              alt="Leftlower.png"
            />
            <img
              className="relative z-10 3xl:ml-[5%] ml-[20%] mt-[48px]"
              src={leftupper}
              alt="LeftUpper.png"
            />
          </div>
          <div className="w-[33%] absolute right-0">
            <img
              className="absolute right-0 top-0"
              src={rightlower}
              alt="rightlower.png"
            />
            <img
              className="relative z-10 3xl:ml-[-5%] 2xl:ml-[-10%] ml-[-20%] mt-[48px]"
              src={rightupper}
              alt="rightupper.png"
            />
          </div>
          <div className="relative sm:ml-[15%] ml-[5%] lg:-mt-28 2xl:-mt-36 sm:-mt-16 -mt-16 sm:w-[70%] w-[90%] z-20">
            <div id="video-laptop">
              {/* <iframe
                className="sm:p-[10%_12%_12.5%_11.5%] p-[11%_12%_12.5%_11.5%]"
                src={`https://www.youtube.com/embed/${VideoUrl}?autoplay=1&mute=1&loop=1&playlist=${VideoUrl}&controls=0&modestbranding=1&quality=hd1080`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              ></iframe> */}
              <div className="laptop">
                <img src={Laptop} alt="" />
                <video src={videourl} controls></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Herosection;
