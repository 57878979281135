import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { Logo, UserAdd } from "../../assets";
import { Navlists } from "./Navlists";
import { LoginLink } from "../../constants/apiEndPoints/Links";
import { SocialLinks } from "./socialLinks";

const Navbar = () => {
  const location = useLocation();
  const [isSidebarResp, setIssidebarResp] = useState(false);
  const overlayRef = useRef();

  const handleOverlayClick = (e) => {
    if (e.target === overlayRef.current) {
      setIssidebarResp(false);
      document.body.classList.remove("no-scroll");
    }
  };

  useEffect(() => {
    if (isSidebarResp) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isSidebarResp]);

  const handleResize = () => {
    if (window.innerWidth > 768 && isSidebarResp) {
      setIssidebarResp(false);
      document.body.classList.remove("no-scroll");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isSidebarResp]);

  const handleScrollToWorkflow = () => {
    const workflowSection = document.getElementById("workflow-section");
    const yOffset = -150;

    if (workflowSection) {
      const y =
        workflowSection.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const handleResponsiveNav = () => {
    handleScrollToWorkflow();
    setIssidebarResp(false);
    document.body.classList.remove("no-scroll");
  };

  return (
    <div className="w-full flex justify-between items-center font-primary relative">
      <div className="LogoBox">
        <Link to={"/"}>
          <img className="w-[120px] lg:w-auto" src={Logo} alt="Logo" />
        </Link>
      </div>
      <ul className="lg:flex hidden xl:space-x-10 space-x-3">
        {Navlists &&
          Navlists.map((item, index) => (
            <Link
              onClick={handleScrollToWorkflow}
              to={item.link}
              key={index}
              className={` xl:font-semibold font-normal ${
                location.pathname === item.link
                  ? "text-[#008E3A] underline"
                  : "text-black"
              } leading-6 xl:text-[17px] text-sm hover:text-[#008E3A] hover:underline duration-150`}
            >
              <li>{item.list}</li>
            </Link>
          ))}
      </ul>
      <div className="lg:flex hidden items-center">
        {/* Social Links and Login Button */}
        {SocialLinks &&
          SocialLinks.map((socail, index) => {
            return (
              <div key={index} className="w-[49px] h-[47px]">
                <Link to={socail.link}>
                  <img src={socail.Logo} alt="socail.svg" />
                </Link>
              </div>
            );
          })}
        <Link to={LoginLink}>
          <button className="UserAddBtn font-primary font-semibold">
            <img src={UserAdd} alt="User Add.svg" />
            <span>Вход</span>
          </button>
        </Link>
      </div>
      <div
        onClick={() => setIssidebarResp(!isSidebarResp)}
        className="HarmBurger lg:hidden block text-3xl"
      >
        <IoMenu />
      </div>

      <div
        ref={overlayRef}
        onClick={handleOverlayClick}
        className={`w-full md:h-screen h-[95vh] bg-[#0000007e] transition-all fixed top-0 ${
          isSidebarResp ? "left-0" : "left-[-100%]"
        } `}
      >
        <div className="sm:w-[50%] w-[90%] h-full bg-white">
          <div className="w-full h-full relative ">
            {/* Responsive Navbar Content */}
            <div className="p-8 border-b">
              <Link to={"/"}>
                <img src={Logo} alt="Logo" className="w-24" />
              </Link>
            </div>
            <ul className="flex flex-col p-8">
              {Navlists &&
                Navlists.map((item, index) => (
                  <Link
                    onClick={handleResponsiveNav}
                    to={item.link}
                    key={index}
                    className={`py-2 hover:text-[#008E3A] hover:underline 
                      ${
                        location.pathname === item.link
                          ? "text-[#008E3A] underline"
                          : "text-black"
                      }
                    `}
                  >
                    {item.list}
                  </Link>
                ))}
            </ul>
            <div className=" flex  flex-col w-full gap-2 absolute bottom-10 left-8">
              {/* Social Links and Login Button */}
              <Link to={LoginLink}>
                <button className="UserAddBtn w-[80%] font-primary font-semibold">
                  <img src={UserAdd} alt="User Add.svg" />
                  <span>Вход</span>
                </button>
              </Link>
              <div className="w-[80%] flex justify-between">
                {SocialLinks &&
                  SocialLinks.map((socail, index) => {
                    return (
                      <div key={index} className="w-[49px] h-[47px]">
                        <Link to={socail.link}>
                          <img src={socail.Logo} alt="socail.svg" />
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
