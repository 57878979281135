import React, { useEffect, useState } from "react";
import { Arrow, Faqpic } from "../../assets";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Styles from "./faq.module.css";
import { useSelector } from "react-redux";
import { fetchData } from "../../axios/CommonApisFunctions.js";

function Icon({ id, open }) {
  return (
    <img
      className={`${
        id === open ? "" : "-rotate-90"
      } h-[45px] w-[45px] transition-transform`}
      src={Arrow}
      alt="Arrow.svg"
    />
  );
}

const FAQ = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const [Faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Language } = useSelector((state) => state.user);

  useEffect(() => {
    const Fetch = async () => {
      try {
        setLoading(true);
        const responseData = await fetchData(Language);
        setFaqs(responseData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error occurred:", error.message);
      }
    };

    Fetch();
  }, [Language]);

  return (
    <div className="font-primary">
      <h1 className="text-[#202648] text-center text-4xl font-bold leading-10">
        Часто Задаваемые Вопросы
      </h1>
      <p className="text-center text-[#4E5478] text-lg font-normal">
        Вот некоторые часто задаваемые вопросы о веб-приложении для генерации
        контента AIword:
      </p>
      <div className="grid md:grid-cols-3 gap-6 my-10">
        <div>
          <img className="h-auto w-full" src={Faqpic} alt="Faqpic.svg" />
        </div>
        <div className="md:col-span-2 space-y-5">
          {loading && "Loading....."}
          {Faqs &&
            Faqs.map((faq) => {
              return (
                <Accordion
                  key={faq.id}
                  className={`${Styles.FaqAcc}`}
                  open={open === faq.id}
                  icon={<Icon id={faq.id} open={open} />}
                >
                  <AccordionHeader
                    className="border-none text-[#202648] sm:text-2xl text-lg text-start font-semibold leading-normal"
                    onClick={() => handleOpen(faq.id)}
                  >
                    {faq.title}
                  </AccordionHeader>
                  <AccordionBody className="text-[#4E5478] sm:text-[22px] text-base font-normal leading-8">
                    <p dangerouslySetInnerHTML={{ __html: faq?.en }} />
                  </AccordionBody>
                </Accordion>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
