import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { CheckMarkfill } from "../../assets";
import { GetData, imageurl } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Projects = () => {
  const [SectionHead, setSectionHead] = useState({
    heading: "",
    heading_2: "",
    description: "",
  });
  const [Subsection, setSubSection] = useState([]);
  const { Language } = useSelector((state) => state.user);

  useEffect(() => {
    const obj = {
      page: "home",
      section: 2,
      lang_id: Language,
    };

    const fetchData = async () => {
      try {
        const Response = await httpRequest.post(GetData, obj);
        if (Response.status === 200 || Response.status === 201) {
          setSectionHead(Response?.data?.content);
          setSubSection(Response?.data?.sub_content);
        }
      } catch (err) {
        console.log("🚀 ~ fetchData ~ err:", err.message);
      }
    };
    fetchData();
  }, [Language]);

  return (
    <div className="w-full">
      <div className="font-secondry space-y-2">
        <h1 className=" text-center text-[#3FC27E] text-lg font-medium leading-7">
          Aiword
        </h1>
        <h1 className="text-[#202648] text-center sm:text-4xl text-2xl font-bold leading-10">
          {SectionHead?.heading || "Почему выбирать AIword?"}
        </h1>
        <p className="text-[#4E5478] text-center text-lg leading-6 font-normal">
          {SectionHead?.description ||
            "Наша платформа разработана с учетом ваших запросов. Вот почему AIword выделяется"}
        </p>
      </div>
      <div className="w-full flex flex-col gap-8 my-8">
        {Subsection &&
          Subsection.map((data, index) => {
            return (
              <div
                key={data.id}
                className={`grid md:grid-cols-2 grid-cols-1 gap-10 font-primary ${styles.MainProject} `}
              >
                <div className={`h-max ${index === 3 ? "order-1" : ""} `}>
                  <img
                    className="h-auto w-full"
                    src={`${imageurl}/${data.image}`}
                    alt="img.png"
                  />
                </div>
                <div className="space-y-3">
                  <h1 className="text-[#111F4A] text-2xl font-bold leading-9">
                    {data.heading || "N/A"}
                    <span className="text-[#45C181]">
                      {" "}
                      {data.heading_2 || "N/A"}
                    </span>
                  </h1>
                  <p className="text-[#5F5F5F] text-[15px] font-normal leading-5">
                    {data.description || "N/A"}
                  </p>
                  <ul className="flex flex-col gap-3">
                    {data.bullets &&
                      data.bullets.map((list, ind) => {
                        return (
                          <li
                            key={ind}
                            className="flex items-center space-x-3 leading-5"
                          >
                            <img src={CheckMarkfill} alt="CheckMarkfill.png" />
                            <span className="text-[#111F4A] text-sm font-normal ">
                              {list}
                            </span>
                          </li>
                        );
                      })}
                  </ul>
                  {data.button_text !== "" && (
                    <Link
                      style={{ width: "max-content" }}
                      className={`${styles.projectBtn}`}
                      to={"/pricing"}
                    >
                      <button>{data.button_text}</button>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Projects;
