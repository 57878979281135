import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import { ArrowFlow } from "../../assets";
import { useSelector } from "react-redux";
import { GetData } from "../../constants/apiEndPoints";
import httpRequest from "../../axios/index.js";

const WorkFlow = () => {
  const [SectionHead, setSectionHead] = useState({
    heading: "",
    heading_2: "",
    description: "",
  });
  const [Subsection, setSubSection] = useState([]);
  const { Language } = useSelector((state) => state.user);

  useEffect(() => {
    const obj = {
      page: "home",
      section: 5,
      lang_id: Language,
    };

    const fetchData = async () => {
      try {
        const Response = await httpRequest.post(GetData, obj);
        if (Response.status === 200 || Response.status === 201) {
          setSectionHead(Response?.data?.content);
          setSubSection(Response?.data?.sub_content);
        }
      } catch (err) {
        console.log("🚀 ~ fetchData ~ err:", err.message);
      }
    };
    fetchData();
  }, [Language]);

  return (
    <div className="font-tertiary">
      <div className="space-y-5">
        <div>
          <h1 className="text-[#45C181] text-center text-[15px] font-normal leading-7">
            Aiword
          </h1>
          <h1 className="text-[#222] text-center text-4xl font-bold leading-10">
            {SectionHead?.heading || "Как это работает"}
          </h1>
        </div>
        <p className="text-[#555] text-center text-[15px] font-normal leading-7">
          {SectionHead?.description ||
            "создает высококачественный контент для вашего веб-сайта или блога быстро и без усилий"}
        </p>
      </div>
      <div className="my-10  grid md:grid-cols-3 sm:grid-cols-2 gap-10">
        {Subsection &&
          Subsection.map((flow, index) => {
            return (
              <div
                key={flow.id}
                className="flex flex-col items-center justify-between gap-4 p-5 relative"
              >
                {index !== Subsection.length - 1 && ( // Check if it's not the last item
                  <img
                    className="absolute sm:block hidden right-[-30%] h-auto w-[50%]"
                    src={ArrowFlow}
                    alt="ArrowFlow.png"
                  />
                )}
                <div
                  className={`${styles.Masks} m-auto font-bold text-white text-lg`}
                >
                  0{index + 1}
                </div>
                <div className="xl:min-h-52 lg:min-h-64 sm:min-h-72">
                  <h1 className="text-xl text-[#222] leading-8 font-semibold text-center">
                    {flow.heading || "Выберите подходящий для вас инструмент"}
                  </h1>
                  <p className="text-[#555] text-[15px] font-normal leading-7 text-center">
                    {flow.description ||
                      "Для начала выберите шаблон, который соответствует вашим личным или деловым потребностям из разнообразия доступных шаблонов."}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default WorkFlow;
